import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import './styles.css';

ReactDOM.render(
  <App />,

  document.getElementById('root'),
);
